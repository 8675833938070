<template>
  <div class="home">
    <NuevasTiendas msg="Nueva tienda" estado="Activo"/>
    <ul id="categorias" >
      <li v-for="tienda in tiendas" :key="tienda.id">
        <Tiendas :nombre=tienda.nombre :tienda=tienda.id :extencion=tienda.extencion  :descripcion=tienda.descripcion :telefono=tienda.telefono :telefonos=tienda.telefonos :correo=tienda.correo :estado=tienda.estado />
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import Tiendas from '@/components/Tiendas.vue'
import NuevasTiendas from '@/components/NuevasTiendas.vue'
import aws_service from '@/services/aws';
export default {
  name: 'Home',
   mounted() {
     console.log("dev");
     this.DescargarTiendas();
  },
  components: {
    Tiendas,
    NuevasTiendas
  },
  data() {
    return {
      tiendas:[]
    };
  },
  methods:
  {
    DescargarTiendas(){
        aws_service.obtenerTiendas().then((response) => {
            console.log(response);
            response.data.forEach(tienda=> {
              console.log(tienda);
              this.tiendas.push({id:tienda.Sk.substring(tienda.Sk.indexOf('#')+1),nombre:tienda.Nombre, extencion:tienda.Extencion, descripcion:tienda.Descripcion, telefono:tienda.Telefono, correo:tienda.Correo, telefonos:tienda.Telefonos,estado:tienda.Estado})
              console.log(this.tiendas);
            });
        }).catch((error) => {
          console.log(error);
           alert("Categoria no guardado!");  
        });  
        
      },
  }
}
</script>
