<template>
    <div class="columns is-mobile is-centered">
  <div class="column is-half">
    <div class="card">
    <b-field class="file is-primary" :class="{'has-name': !!file}">
        <b-upload v-model="file" class="file-label" accept="image/*" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir imagen</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
        </b-upload>
    </b-field>
  <div class="card-content">
    <div class="content">
      <b-field>
            <b-input v-model="nombre" placeholder="Nombre"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
      <b-field>
            <b-input v-model="descripcion" placeholder="Descripcion"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
      
      <b-field>
            <b-input v-model="telefono" placeholder="Telefono"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
      
      <b-field>
            <b-input v-model="correo" placeholder="Correo Electrónico"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
      <b-field>
            <b-taginput
                v-model="telefonos"
              
                autocomplete
                :allow-new="allowNew"
                :open-on-focus="openOnFocus"
                field="nombre"
                icon="label"
                placeholder="Teléfonos autorizados"
        >
            </b-taginput>
        </b-field>
        <b-field>
            <b-switch v-model="estado"
                true-value="Activo"
                false-value="Inactivo">
                {{ estado }}
            </b-switch>
        </b-field>
        <b-button @click="GuardarTienda('Guardar!', $event)">{{msg}}</b-button>
        
    </div>
     
  </div>
</div>
  </div>
</div>
</template>

<script>
import aws_service from '@/services/aws';
import { uuid } from 'vue-uuid';
export default {
  props:
  {
    idTienda:String,
    msg:String,
    nombre:String,
    descripcion:String,
    telefono:String,
    extencion:String,
    correo:String,
    telefonos:Array(),
   estado: String,
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      file: null,
      allowNew: true,
      openOnFocus: false,
    };
  },
  methods:
  {
    GuardarTienda(msg,e){
        e.preventDefault();
        var re = /(?:\.([^.]+))?$/;
        if(!this.idTienda)
        {
          var nuevoId=uuid.v1();
          //var archivo_img = this.$refs.file_img.files[0];
           
           var extencion = re.exec(this.file.name)[1]; 
           
          aws_service.guardarImagenTienda(nuevoId,this.file,extencion).then((response) => {
              console.log(response);
              var data= {tienda:nuevoId,nombre:this.nombre,extencion:extencion, descripcion:this.descripcion, telefono:this.telefono,correo:this.correo, telefonos:this.telefonos, estado :this.estado}
              aws_service.guardarTienda(data).then((response) => {
                  console.log(response);
                  alert("Tienda guardada!");
                  location.reload();
              }).catch((error) => {
                console.log(error);
                 alert("Tienda no guardado!");  
              });  
          }).catch((error) => {
            console.log(error);
             alert("Tiienda no guardada!");  
          });
        }
        else{
          var extencion1=this.extencion
          if(this.file)
          {
            
            extencion1 = re.exec(this.file.name)[1]; 
            //alert(extencion1);
            aws_service.guardarImagenTienda(this.idTienda,this.file,extencion1).then((response) => {
                console.log(response);
            }).catch((error) => {
              console.log(error);
               alert("Tienda no guardado!");  
            });
          }
          var data= {tienda:this.idTienda,nombre:this.nombre,extencion:extencion1, descripcion:this.descripcion, 
          telefono:this.telefono,correo:this.correo, telefonos:this.telefonos, estado :this.estado}
              aws_service.guardarTienda(data).then((response) => {
                  console.log(response);
                  alert("Tienda guardada!");
                  location.reload();
              }).catch((error) => {
                console.log(error);
                 alert("Tienda no guardada!");  
              });  
        
        }
        
      }
    
  }
};
</script>

