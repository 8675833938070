<template>
    <div class="columns is-mobile is-centered">
  <div class="column is-half">
   
    <div class="card">
  <div class="card-image">
    <figure class="image is-4by3">
      <img src="@/assets/intro.jpg" alt="Placeholder image">
    </figure>
  </div>
  <div class="card-content">
    
    
    <div class="content">
      <div v-if="!this.tokenPayload">
      <b-field>
            <b-input v-model='usuario' placeholder="Usuario"
                
                icon-pack="fas"
                icon="envelope">
            </b-input>
        </b-field>
        
        <b-field >
            <b-input v-model='clave' placeholder="Clave"
            type="password"
            icon-pack="fas"
                icon="lock"
                password-reveal>
            </b-input>
        </b-field>
        <b-button @click="login">Entrar</b-button>
    </div>
    <div v-else>
    <b-field>
           <label class="label">Bienvenido {{this.tokenPayload.data.name}}</label>
        </b-field>
        <b-button @click="Logout">Salir</b-button>
        </div>
    </div>
     
  </div>
</div>
  </div>
</div>
</template>

<script>
import aws_service from '@/services/aws';
import {mapState, mapMutations} from 'vuex';
export default {
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      usuario:"",
      clave:""
    };
  },
  computed:{
      ...mapState([
    'token','tokenPayload','system_id'
    ])},
    methods: {
    ...mapMutations(["asignar_tienda","new_token","logout"]),
    login: function() {
        
        var data={"system_id":this.system_id,"username":this.usuario,"password":this.clave}
        aws_service.login(data).then((response) => {
          
          this.new_token(response.data.token);
         console.log(this.tokenPayload)  
         this.DescargarTiendas();
        }).catch((error) => {
          console.log(error);
          alert(error);
          console.log(this.token);
          
        });
    },
   Logout()
   {
     this.logout();
     location.reload();

   },
   DescargarTiendas(){
        aws_service.obtenerTiendas().then((response) => {
            console.log(response);
            response.data.forEach(tienda=> {
              console.log(tienda);
              this.tiendas.push({id:tienda.Sk.substring(tienda.Sk.indexOf('#')+1),nombre:tienda.Nombre, extencion:tienda.Extencion, descripcion:tienda.Descripcion, telefono:tienda.Telefono, correo:tienda.Correo})
              console.log(this.tiendas);
            });
        }).catch((error) => {
          console.log(error);
          // alert("Categoria no guardado!");  
        });  
        
      },
    
  }
};
</script>
