<template>
    <div class="sidebar-page">
        <section class="sidebar-layout">
             <b-sidebar
                position="static"
                :fullheight="fullheight"
                :mobile="mobile"
                
                :expand-on-hover="expandOnHover"
                :reduce="reduce"
                type="is-light"
                open
            >
                <div class="p-1">
                    <div class="block">
                <!--    <img
                        src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
                        alt="Lightweight UI components for Vue.js based on Bulma"
                    />-->
                    </div>
                    <b-menu class="is-custom-mobile">
                        <b-menu-list label="Menu">
                            <router-link to="/"><b-menu-item icon="home" label="Inicio"></b-menu-item></router-link>
                            <router-link to="/tiendas"><b-menu-item icon="clipboard-list-outline" label="Tiendas"></b-menu-item></router-link>
                          
                            
                        </b-menu-list>
                        
                    </b-menu>
                </div>
            </b-sidebar>

            
        </section>
    </div>
</template>

<script>
export default {
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      fullheight: true,
      
    };
  }
};
</script>
