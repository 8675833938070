<template>
  <div id="app">
    <div class="columns">
      <div  v-if="this.tokenPayload" class="column is-narrow">
        <Menu msg="Welcome to Your Vue.js App"/>
      </div>
      <div class="column">
        <div >
          <router-view/>
        </div>
      </div>
    </div>
     
    
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'

import {mapState} from 'vuex';

export default {
  name: 'Home',
  components: {
    Menu
  },
  computed:{
      ...mapState([
    'tokenPayload'
    ])},
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
