import Axios from 'axios';
import store from '../store'


const RESOURCE_STORE = 'https://dyr7cj5zoc.execute-api.us-west-2.amazonaws.com/api';
const RESOURCE_STORE_FILES = 'https://xvim4wxi0f.execute-api.us-west-2.amazonaws.com/api';
 //const RESOURCE_STORE = 'http://54.203.155.133:8001';
//const RESOURCE_STORE_FILES= 'http://54.202.2.0:8000';

const RESOURCE_SECURITY = 'https://f65p9by2x0.execute-api.us-west-2.amazonaws.com/api';


Axios.interceptors.request.use(function(config) {
    const auth_token = store.getters.token;
    if(auth_token!='') {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

export default {

   login(datos)
  {
    
    return Axios.post( `${RESOURCE_SECURITY}/login/`,datos)
  },
  guardarImagenTienda(tienda,img,extencion)
  {
    let formData = new FormData();
    formData.append('img',img);
    formData.append('tienda',tienda);
    formData.append('extencion',extencion);
    
    return Axios.post( `${RESOURCE_STORE_FILES}/cargar_tienda`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  },
  guardarTienda(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_tienda`,data)
  },
  obtenerTiendas()
  {
    return Axios.get( `${RESOURCE_STORE}/obtener_tiendas`)
  },
  obtenerImagenCategoria(data)
  {
    return Axios.post( `${RESOURCE_STORE_FILES}/descargar_categoria`,data)
  },
  
  obtenerCategoriasProductos(data)
  {
    return Axios.post( `${RESOURCE_STORE}/obtener_categorias_producto`,data)
  },
  guardarConfiguracion(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_configuracion`,data)
  },
  guardarCategoriaProducto(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_categoria_producto`,data)
  },
  obtenerProductos(data)
  {
    return Axios.post( `${RESOURCE_STORE}/obtener_productos`,data)
  },

  
};